import { inject, ref } from 'vue';
import { createSharedComposable } from '@vueuse/core';

export const modalInjectionKey = Symbol('frontplane.modal');

export const _useModal = () => {
    const modalState = inject(modalInjectionKey);

    const isOpen = ref(false);

    function open(component, props = undefined) {
        if (!modalState) {
            throw new Error('useModal() is called without provider');
        }

        modalState.value = {
            component,
            props: props ?? {},
        };

        isOpen.value = true;
    }

    async function close() {
        if (!modalState) {
            return;
        }

        isOpen.value = false;

        setTimeout(() => {
            reset();
        }, 500);
    }

    function reset() {
        if (!modalState) {
            return;
        }

        modalState.value = {
            component: 'div',
            props: {},
        };
    }

    function patch(props) {
        if (!modalState) {
            return;
        }

        modalState.value = {
            ...modalState.value,
            props: {
                ...modalState.value.props,
                ...props,
            },
        };
    }

    return {
        open,
        close,
        reset,
        patch,
        isOpen,
    };
};

export const useModal = createSharedComposable(_useModal);
