import i18n from '@/lang/i18n';

const commands = [
    // General
    {
        name: 'dashboard',
        label: i18n.global.t('general.dashboard'),
        keywords: ['home', 'main'],
        type: 'page',
        to: { name: 'dashboard' },
    },
    {
        name: 'settings',
        label: i18n.global.t('general.settings'),
        keywords: [],
        type: 'page',
        to: { name: 'settings' },
    },

    // Domains
    {
        name: 'domains/index',
        label: 'Domains',
        keywords: [],
        type: 'page',
        to: { name: 'domains' },
    },
    {
        name: 'domains/create',
        label: i18n.global.t('button.new_domain'),
        keywords: ['register domain', 'add domain', 'create domain'],
        type: 'page',
        to: { name: 'new domain' },
    },

    // SSL certificates
    {
        name: 'ssl/index',
        label: i18n.global.t('general.ssl_certificates'),
        keywords: [],
        type: 'page',
        to: { name: 'ssl' },
    },
    {
        name: 'ssl/create',
        label: i18n.global.t('button.new_ssl_certificate'),
        keywords: ['new ssl', 'new certificate', 'order ssl', 'register certificate'],
        type: 'page',
        to: { name: 'new ssl' },
    },

    // Contacts
    {
        name: 'contacts/index',
        label: i18n.global.t('general.contacts'),
        keywords: [],
        type: 'page',
        to: { name: 'contacts' },
    },
    {
        name: 'contacts/create',
        label: i18n.global.t('button.new_contact'),
        keywords: [],
        type: 'page',
        to: { name: 'new contact' },
    },
    {
        name: 'companies/create',
        label: i18n.global.t('button.new_company'),
        keywords: [],
        type: 'page',
        to: { name: 'new company' },
    },

    // Support
    {
        name: 'support/index',
        label: i18n.global.t('general.support'),
        keywords: ['ticket'],
        type: 'page',
        to: { name: 'support' },
    },

    // Team
    {
        name: 'team/index',
        label: i18n.global.t('general.team'),
        keywords: [],
        type: 'page',
        to: { name: 'team' },
    },

    // Invoices
    {
        name: 'invoices/index',
        label: i18n.global.t('general.invoices'),
        keywords: [],
        type: 'page',
        to: { name: 'invoices' },
    },

    // External
    {
        name: 'external/roadmap',
        label: 'Roadmap',
        keywords: ['changelog'],
        type: 'external',
        href: 'https://roadmap.cyberfusion.io/roadmap',
    },

    // Actions
    {
        name: 'action/close-search',
        label: i18n.global.t('search.actions.close_modal'),
        keywords: [],
        type: 'page',
        action: ({ close }) => close(),
    },
];

export default commands;
