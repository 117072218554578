<script setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import CFSearchContainer from '@/components/Search/CFSearchContainer.vue';
import { useAppStore } from '@/stores/app.js';

const appStore = useAppStore();

const closeSearchModal = () => {
    appStore.showSearchModal = false;
};
</script>

<template>
    <TransitionRoot
        as="template"
        :show="appStore.showSearchModal"
    >
        <Dialog
            as="div"
            class="relative z-70"
            @close="closeSearchModal"
        >
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-70 overflow-y-auto">
                <div class="flex h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogPanel
                            class="relative h-full w-full transform overflow-hidden bg-white px-10 py-6 text-left shadow-xl transition-all sm:my-8"
                        >
                            <CFSearchContainer @close="closeSearchModal" />
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
