<script setup>
import CFNotification from '@/components/CFNotification.vue';
import { useNotificationsStore } from '@/stores/notifications.js';
import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import api from '@/plugins/axios.js';

const notificationsStore = useNotificationsStore();

const { isPending, data } = useQuery({
    queryKey: ['notifications-dashboard'],
    queryFn: async () => {
        const { data } = await api.get(`/general/notifications`, {
            params: {
                per_page: 3,
            },
        });

        return data.data;
    },
    refetchOnWindowFocus: 'always',
    placeholderData: keepPreviousData,
});
</script>

<template>
    <div class="mb-12">
        <h2 class="mb-4 text-base font-bold">{{ $t('dashboard.notifications') }}</h2>
        <div
            v-if="!isPending"
            class="grid grid-cols-1 gap-2 lg:grid-cols-3 lg:gap-x-4"
        >
            <CFNotification
                v-for="notification in data"
                :key="notification.id"
                v-tooltip="notification.attributes.subject"
                :notification="notification"
                color="primary"
                @view-notification="notificationsStore.show(notification)"
            />
        </div>
    </div>
</template>
