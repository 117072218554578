<script setup>
import { useNotificationsStore } from '@/stores/notifications';
import BellIcon from '@/components/icons/BellIcon.vue';
import { Tooltip as VueTooltip } from 'floating-vue';
import { defineShortcuts } from '@/composables/defineShortcuts.js';
import CFKbd from '@/components/CFKbd.vue';
import CFRoundedButton from '@/components/ui/CFRoundedButton.vue';
import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import api from '@/plugins/axios.js';

const notificationStore = useNotificationsStore();

const { isPending, isError, isFetching, data } = useQuery({
    queryKey: ['notifications-unread'],
    queryFn: async () => {
        const { data } = await api.get('/general/notifications', {
            params: {
                filter: {
                    read: 0,
                },
            },
        });

        return data.data.length;
    },
    refetchOnWindowFocus: 'always',
});

const hasUnreadNotifications = computed(() => {
    if (isPending.value || isError.value || isFetching.value) {
        return false;
    }

    return data.value > 0;
});

defineShortcuts({
    shift_n: {
        handler: () => {
            if (notificationStore.isOpen) {
                notificationStore.close();
            } else {
                notificationStore.open();
            }
        },
    },
});
</script>

<template>
    <VueTooltip theme="dark-tooltip">
        <CFRoundedButton
            :icon="BellIcon"
            color="primary"
            size="lg"
            :indicator="hasUnreadNotifications"
            @click="notificationStore.open()"
        />

        <template #popper>
            <div class="flex flex-col items-center">
                <strong>{{ $t('shortcuts.notifications') }}</strong>
                <CFKbd
                    value="Shift + N"
                    class="bg-primary text-neutrals-600 ring-primary"
                />
            </div>
        </template>
    </VueTooltip>
</template>
