<template>
    <div class="content-placeholders-heading content-placeholders-spacing">
        <div class="content-placeholders-heading__content">
            <div class="content-placeholders-heading__title content-placeholders" />
            <div class="content-placeholders-heading__subtitle content-placeholders" />
        </div>
    </div>
</template>

<style scoped>
@reference "@css/app.css";

.content-placeholders-heading {
    display: flex;

    .content-placeholders-heading__content {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
    }

    .content-placeholders-heading__title {
        width: 85%;
        margin-bottom: 1rem;
        background: #f5f5f5;
    }

    .content-placeholders-heading__subtitle {
        width: 90%;
    }
}
</style>
