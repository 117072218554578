<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        height="24"
        width="24"
    >
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M10.5 23.5h-9c-0.26522 0 -0.51957 -0.1054 -0.707107 -0.2929C0.605357 23.0196 0.5 22.7652 0.5 22.5v-21c0 -0.26522 0.105357 -0.51957 0.292893 -0.707107C0.98043 0.605357 1.23478 0.5 1.5 0.5h13.294c0.1316 0.001076 0.2618 0.028131 0.3829 0.079612 0.1212 0.051481 0.231 0.126376 0.3231 0.220388l3.707 3.7c0.1875 0.18749 0.2929 0.4418 0.293 0.707V8.5"
            stroke-width="1"
        ></path>
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m6.5 7.00391 -3 2.99999 3 3"
            stroke-width="1"
        ></path>
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m9.5 7.00391 3 2.99999 -3 3"
            stroke-width="1"
        ></path>
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M17.25 23.5c3.3137 0 6 -2.6863 6 -6s-2.6863 -6 -6 -6 -6 2.6863 -6 6 2.6863 6 6 6Z"
            stroke-width="1"
        ></path>
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M17.25 20.4961v-3.5c0 -0.1326 -0.0527 -0.2598 -0.1464 -0.3536 -0.0938 -0.0937 -0.221 -0.1464 -0.3536 -0.1464h-1"
            stroke-width="1"
        ></path>
        <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 20.5h3"
            stroke-width="1"
        ></path>
        <path
            stroke="currentColor"
            d="M16.5 14.4961c-0.1381 0 -0.25 -0.1119 -0.25 -0.25s0.1119 -0.25 0.25 -0.25"
            stroke-width="1"
        ></path>
        <path
            stroke="currentColor"
            d="M16.5 14.4961c0.1381 0 0.25 -0.1119 0.25 -0.25s-0.1119 -0.25 -0.25 -0.25"
            stroke-width="1"
        ></path>
    </svg>
</template>
