import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import api from '@/plugins/axios.js';
import { useUserStore } from '@/stores/user.js';
import { useToast } from 'vue-toastification';
import { useLocalStorage } from '@vueuse/core';
import i18n from '@/lang/i18n';
import Bugsnag from '@bugsnag/js';
import { unexpectedError } from '@/utils/unexpected-error.js';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';

export const useSupportStore = defineStore('support', () => {
    const isLoading = ref(true);
    const isLoadingTickets = ref(false);
    const isLoadingReply = ref(false);
    const isLoadingMoreTickets = ref(false);
    const onlyPersonalTickets = useLocalStorage('onlyPersonalTickets', false);
    const onlyOpenTickets = useLocalStorage('onlyOpenTickets', false);
    const tickets = ref([]);
    const activeTicket = ref(null);
    const activeTicketReplies = ref([]);
    const activeTicketProfilePictures = ref({});
    const activeTicketAttachments = ref([]);

    const replyAttachments = ref([]);

    const error = ref(null);

    const currentPage = ref(1);
    const hasMore = ref(true);

    const toast = useToast();

    const hasEnabledFilters = computed(() => onlyPersonalTickets.value || onlyOpenTickets.value);

    const getTickets = async (params = {}, opts = {}) => {
        error.value = null;
        hasMore.value = true;

        const defaultOptions = {
            loadActiveTicket: true,
            throwError: true,
        };
        const options = { ...defaultOptions, ...opts };

        const defaultParams = {
            page: 1,
            per_page: 10,
        };
        params = {
            ...defaultParams,
            ...params,
            group: onlyPersonalTickets.value ? 'user' : 'team',
            status: onlyOpenTickets.value ? 'open' : null,
        };

        if (params.page === 1) {
            isLoadingTickets.value = true;
        }

        if (!activeTicket.value) {
            isLoading.value = true;
        }

        return api
            .get(`/general/support-messages`, {
                params,
                silenceErrors: true,
            })
            .then(async (response) => {
                if (response.data.meta.current_page === response.data.meta.last_page) {
                    hasMore.value = false;
                }

                if (params.page === 1) {
                    tickets.value = response.data.data;
                } else {
                    tickets.value = [...tickets.value, ...response.data.data];
                }

                if (options.loadActiveTicket && tickets.value.length) {
                    if (
                        activeTicket.value &&
                        activeTicket.value.updated_at === tickets.value[0].updated_at &&
                        tickets.value.map((ticket) => ticket.id).includes(activeTicket.value.id)
                    ) {
                        return;
                    }

                    isLoading.value = true;

                    activeTicket.value = tickets.value[0];

                    await Promise.all([markAsRead(activeTicket.value), getReplies(tickets.value[0].id)]);
                }
            })
            .catch((e) => {
                dehydrate();
                hasMore.value = false;

                if (options.throwError) {
                    toast.error(i18n.global.t('error.could_not_load_tickets'));
                }

                error.value = e;
            })
            .finally(() => {
                isLoading.value = false;
                isLoadingTickets.value = false;
                currentPage.value = params.page;
            });
    };

    const loadMoreTickets = async () => {
        if (!hasMore.value || isLoadingTickets.value) {
            return;
        }

        isLoadingMoreTickets.value = true;

        await getTickets({ page: ++currentPage.value }, { loadActiveTicket: true, throwError: true });

        isLoadingMoreTickets.value = false;
    };

    const addTicket = (ticket) => {
        tickets.value.unshift(ticket);
    };

    const setActiveTicket = async (ticket) => {
        if (activeTicket.value && activeTicket.value.id === ticket.id) {
            return;
        }

        isLoading.value = true;
        activeTicket.value = ticket;

        await getReplies(ticket.id);

        isLoading.value = false;
    };

    const isActiveTicket = (ticket) => {
        return activeTicket.value && activeTicket.value.id === ticket.id;
    };

    const getReplies = async (id) => {
        return api
            .get(`/general/support-messages/${id}/replies`)
            .then(async (response) => {
                activeTicketReplies.value = response.data.data;

                // get all attachments and retrieve their data
                activeTicketAttachments.value = response.data.included.filter((item) => item.type === 'attachment');

                // if the included data is an object, it means malformed response
                if (!Array.isArray(response.data.included)) {
                    Bugsnag.notify(new Error('Malformed response from getReplies'));
                    return;
                }

                // get all participating users and retrieve their profile pictures
                let userIds = response.data.included.filter((item) => item.type === 'user').map((item) => item.id);

                if (userIds.length) {
                    try {
                        await fetchProfilePictures(userIds);
                    } catch (e) {
                        console.error(e);
                    }
                }
            })
            .catch(() => {
                toast.error(i18n.global.t('error.could_not_load_replies'));

                activeTicketReplies.value = [];
                activeTicketAttachments.value = [];
                activeTicketProfilePictures.value = {};
            });
    };

    const fetchProfilePictures = async (userIds) => {
        let promiseList = [];

        for (let i = 0; i < userIds.length; i++) {
            promiseList.push(
                api.get(`/general/users/${userIds[i]}/profile-picture`, {
                    responseType: 'blob',
                })
            );
        }

        return Promise.allSettled(promiseList)
            .then((responses) => {
                for (let i = 0; i < responses.length; i++) {
                    // skip rejected promises, use placeholder instead
                    if (responses[i].status === 'rejected') {
                        continue;
                    }

                    // skip non-200 status codes (204 means no profile picture)
                    if (responses[i].value.status !== 200) {
                        continue;
                    }

                    const urlCreator = window.URL || window.webkitURL;
                    activeTicketProfilePictures.value[userIds[i]] = urlCreator.createObjectURL(responses[i].value.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getProfilePicture = (userId) => {
        return activeTicketProfilePictures.value[userId] ?? null;
    };

    const getAttachment = (attachmentId) => {
        return activeTicketAttachments.value[attachmentId] ?? null;
    };

    const addAttachment = (file) => {
        if (replyAttachments.value.length >= 5) {
            return;
        }

        replyAttachments.value.push(file);
    };

    const removeAttachment = (index) => {
        replyAttachments.value.splice(index, 1);
    };

    const addTemporaryReply = (message) => {
        const userStore = useUserStore();
        const pendingId = nanoid();

        activeTicketReplies.value.push({
            id: pendingId,
            type: 'supportMessageReply',
            placeholder: true,
            attributes: {
                message: message.replace(/\n/g, '<br />'),
                created_at: dayjs().toISOString(),
                is_operator: false,
                operator_avatar: null,
                name: userStore.user.attributes.full_name,
            },
            relationships: {
                user: {
                    data: {
                        id: userStore.user.id,
                        type: 'user',
                    },
                },
                supportMessage: {
                    data: {
                        id: activeTicket.value.id,
                        type: 'supportMessage',
                    },
                },
                attachments: {
                    data: [],
                },
            },
        });

        return pendingId;
    };

    const createReply = async (data) => {
        const userStore = useUserStore();

        isLoadingReply.value = true;

        const formData = {
            ...data,
            attachments: replyAttachments.value,
        };

        // Add temporary reply to the list
        const pendingId = addTemporaryReply(data.message);

        return api
            .post(`/general/support-messages/${activeTicket.value.id}/replies`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(async (response) => {
                // Replace temporary reply with the real one
                const index = activeTicketReplies.value.findIndex((reply) => reply.id === pendingId);

                // TODO: fix in Midplane
                // This is done because the response contains an empty user relationship
                const newReply = response.data.data;
                newReply.relationships.user = {
                    data: {
                        id: userStore.user.id,
                        type: 'user',
                    },
                };

                if (index !== -1) {
                    activeTicketReplies.value[index] = newReply;
                } else {
                    activeTicketReplies.value.push(newReply);
                }

                const newAttachments = response.data.included.filter((item) => item.type === 'attachment');

                // Add new attachments to the list
                activeTicketAttachments.value = [...activeTicketAttachments.value, ...newAttachments];

                replyAttachments.value = [];

                // Reopen ticket if it was closed
                activeTicket.value.attributes.status = 'Open';

                // Set new replies count for active ticket
                activeTicket.value.attributes.messages_count += 1;

                return true;
            })
            .catch((e) => {
                // mark temporary reply as failed
                const index = activeTicketReplies.value.findIndex((reply) => reply.id === pendingId);

                if (index !== -1) {
                    activeTicketReplies.value[index].failed = true;
                }

                unexpectedError(e, i18n.global.t('error.could_not_create_reply'));

                return false;
            })
            .finally(() => (isLoadingReply.value = false));
    };

    const markAsRead = async (ticket) => {
        if (!ticket || ticket.attributes.read_at) {
            return;
        }

        try {
            await api.post(`/general/support-messages/${ticket.id}/read`);

            ticket.attributes.read_at = dayjs().toISOString();

            if (activeTicket.value && activeTicket.value.id === ticket.id) {
                activeTicket.value.attributes.read_at = ticket.attributes.read_at;
            }
        } catch (e) {
            unexpectedError(e);
        }
    };

    const closeTicket = async (ticket) => {
        if (!ticket) {
            return false;
        }

        if (ticket.attributes.status === 'Closed') {
            return true;
        }

        try {
            await api.post(`/general/support-messages/${ticket.id}/close`);

            ticket.attributes.status = 'Closed';

            return true;
        } catch (e) {
            unexpectedError(e);

            return false;
        }
    };

    const resetFilters = () => {
        isLoading.value = true;
        onlyPersonalTickets.value = false;
        onlyOpenTickets.value = false;
    };

    watch([onlyPersonalTickets, onlyOpenTickets], () => getTickets());

    const dehydrate = () => {
        tickets.value = [];
        activeTicket.value = null;
        activeTicketReplies.value = [];
        activeTicketProfilePictures.value = {};
        activeTicketAttachments.value = [];
        replyAttachments.value = [];
        error.value = null;
    };

    return {
        isLoading,
        isLoadingTickets,
        isLoadingReply,
        isLoadingMoreTickets,
        onlyPersonalTickets,
        onlyOpenTickets,
        tickets,
        activeTicket,
        activeTicketReplies,
        activeTicketAttachments,
        replyAttachments,
        error,
        hasEnabledFilters,
        getTickets,
        loadMoreTickets,
        addTicket,
        setActiveTicket,
        isActiveTicket,
        getReplies,
        getProfilePicture,
        getAttachment,
        addAttachment,
        removeAttachment,
        createReply,
        markAsRead,
        closeTicket,
        resetFilters,
        dehydrate,
    };
});
