import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import api from '@/plugins/axios';

export const useNotificationsStore = defineStore('notifications', () => {
    const notifications = ref([]);
    const selectedNotification = ref(null);
    const isOpen = ref(false);
    const isLoading = ref(false);

    const currentPage = ref(1);
    const hasMore = ref(true);

    const hasOpenNotification = computed(() => {
        return selectedNotification.value !== null;
    });

    const open = () => {
        if (isOpen.value) {
            return;
        }

        if (!selectedNotification.value) {
            getNotifications();
        }

        isOpen.value = true;
    };

    const close = () => {
        isOpen.value = false;

        // Set a timeout so that the notification sidebar's
        // transition can finish closing
        setTimeout(() => {
            selectedNotification.value = null;
        }, 1000);
    };

    const show = (notification) => {
        selectedNotification.value = notification;
        open();
    };

    const markAsRead = (notification) => {
        api.put(`/general/notifications/${notification.id}/read`).then(() => {
            notification.attributes.read_at = new Date().toISOString();
        });
    };

    const clear = () => {
        notifications.value = [];
        selectedNotification.value = null;
        isOpen.value = false;
        isLoading.value = false;
        currentPage.value = 1;
        hasMore.value = true;
    };

    const getNotifications = (params = {}) => {
        isLoading.value = true;
        hasMore.value = true;

        const defaultParams = {
            page: 1,
            perPage: 25,
        };

        params = { ...defaultParams, ...params };

        api.get(`/general/notifications`, { params })
            .then((response) => {
                if (response.data.meta.current_page >= response.data.meta.last_page) {
                    hasMore.value = false;
                }

                if (params.page === 1) {
                    notifications.value = response.data.data;
                }

                if (params.page > 1) {
                    notifications.value = [...notifications.value, ...response.data.data];
                }
            })
            .catch(() => (hasMore.value = false))
            .finally(() => {
                currentPage.value = params.page;
                isLoading.value = false;
            });
    };

    const loadMore = (params = {}) => {
        if (!hasMore.value || isLoading.value) {
            return;
        }

        getNotifications({
            ...params,
            page: ++currentPage.value,
        });
    };

    const dehydrate = async () => {
        clear();
    };

    return {
        notifications,
        selectedNotification,
        isOpen,
        isLoading,
        hasOpenNotification,
        loadMore,
        open,
        close,
        show,
        markAsRead,
        clear,
        getNotifications,
        dehydrate,
    };
});
