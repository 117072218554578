<script setup>
import CFNavbarItem from '@/components/CFNavbarItem.vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { computed } from 'vue';
import { useUserStore } from '@/stores/user';
import CFMigrationSidebarAlert from '@/components/CFMigrationSidebarAlert.vue';
import { usePermissionsStore } from '@/stores/permissions.js';
import { useAccountStatus } from '@/composables/useAccountStatus.js';
import { useTeamStore } from '@/stores/team.js';
import Logout2Icon from '@/components/icons/Logout2Icon.vue';
import SettingsIcon from '@/components/icons/SettingsIcon.vue';
import CollapseMenuIcon from '@/components/icons/CollapseMenuIcon.vue';
import LayoutDashboardIcon from '@/components/icons/LayoutDashboardIcon.vue';
import NetworkStarIcon from '@/components/icons/NetworkStarIcon.vue';
import AppWindowLockIcon from '@/components/icons/AppWindowLockIcon.vue';
import BookAddressIcon from '@/components/icons/BookAddressIcon.vue';
import BusinessTeamGoalIcon from '@/components/icons/BusinessTeamGoalIcon.vue';
import HeadphonesCustomerSupportIcon from '@/components/icons/HeadphonesCustomerSupportIcon.vue';
import AccountingInvoiceIcon from '@/components/icons/AccountingInvoiceIcon.vue';
import { useAppStore } from '@/stores/app.js';
import { storeToRefs } from 'pinia';
import CFQuickAddPopover from '@/components/CFQuickAddPopover.vue';
import AlertCircleIcon from '@/components/icons/AlertCircleIcon.vue';
import { UserRole } from '@/constants/roles.js';

const { t } = useI18n();
const router = useRouter();
const userStore = useUserStore();
const teamStore = useTeamStore();
const { hasAllPermissions } = usePermissionsStore();
const { hasFullAccess } = useAccountStatus();

const appStore = useAppStore();
const { sidebarIsOpen } = storeToRefs(appStore);

const navigation = computed(() => [
    {
        name: t('general.dashboard'),
        href: router.resolve({ name: 'dashboard' }).href,
        icon: LayoutDashboardIcon,
        guarded: true,
    },
    {
        name: t('general.domain_hosting'),
        href: router.resolve({ name: 'domains' }).href,
        icon: NetworkStarIcon,
        permissions: ['manage domains'],
        guarded: true,
    },
    {
        name: t('general.ssl'),
        href: router.resolve({ name: 'ssl' }).href,
        icon: AppWindowLockIcon,
        permissions: ['manage ssl-certificates'],
        guarded: true,
    },
    {
        name: t('general.contacts'),
        href: router.resolve({ name: 'contacts' }).href,
        icon: BookAddressIcon,
        permissions: ['manage teams'],
        guarded: true,
    },
    {
        name: t('general.team'),
        href: router.resolve({ name: 'team' }).href,
        icon: BusinessTeamGoalIcon,
    },
    {
        name: t('general.support'),
        href: router.resolve({ name: 'support' }).href,
        icon: HeadphonesCustomerSupportIcon,
        permissions: ['manage support-messages'],
        guarded: true,
    },
    {
        name: t('general.invoices'),
        href: router.resolve({ name: 'invoices' }).href,
        icon: AccountingInvoiceIcon,
        permissions: ['manage financial'],
        guarded: true,
    },
]);

const filteredNavigation = computed(() =>
    navigation.value.filter((item) => {
        if (item.guarded && !hasFullAccess.value) {
            return false;
        }

        if (!item.permissions || !Array.isArray(item.permissions)) {
            return true;
        }

        return hasAllPermissions(item.permissions);
    })
);

const logout = () => router.push({ name: 'logout' });
</script>

<template>
    <aside
        id="sidebar"
        class="sidebar"
        :class="sidebarIsOpen ? 'opened' : 'closed'"
    >
        <nav class="flex h-full w-full flex-col">
            <div class="logo-container">
                <img
                    alt="Cyberfusion logo"
                    class="invert"
                    src="/Cyberfusion_Logo.svg"
                />

                <svg
                    class="letter h-9 w-9 fill-current"
                    viewBox="0 0 142.07 154.04"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M121.98,110.12c-6.7,15.88-22.16,27.27-46.21,27.27-37.26,0-56-28.1-56-60.37s18.95-60.37,56-60.37c23.95,0,37.54,9.98,45.8,27.06h20.03C132,18.63,111.75,0,75.77,0,26.85,0,0,35.18,0,77.02s26.85,77.02,75.77,77.02c36.48,0,59.44-20.65,66.3-43.92,0,0-20.09,0-20.09,0Z"
                    />
                </svg>
            </div>

            <div
                v-if="userStore.isVerified"
                class="grow"
            >
                <div class="navigation">
                    <div class="overflow-x-hidden overflow-y-auto">
                        <div class="flex w-full flex-col">
                            <CFNavbarItem
                                v-for="item in filteredNavigation"
                                :key="item.name"
                                :item="item"
                            />
                        </div>
                    </div>

                    <CFMigrationSidebarAlert
                        v-if="
                            hasFullAccess &&
                            sidebarIsOpen &&
                            userStore.hasRole([UserRole.Developer, UserRole.TeamAdmin, UserRole.SuperUser])
                        "
                    />
                </div>
                <CFQuickAddPopover
                    v-if="userStore.hasRole([UserRole.Developer, UserRole.TeamAdmin, UserRole.SuperUser])"
                />
            </div>

            <div class="relative px-8 pb-8">
                <div class="navbar-profile">
                    <div class="navbar-profile--avatar">
                        <img
                            v-if="userStore.profilePicture"
                            :src="userStore.profilePicture"
                            alt="Profile picture"
                            height="36"
                            width="36"
                        />
                        <div
                            v-else
                            class="from-cf-green to-cf-blue inline-flex size-9 items-center justify-center bg-linear-to-tr text-white select-none"
                        >
                            {{ userStore.user.attributes.initials }}
                        </div>
                    </div>
                    <div class="navbar-profile--name">
                        <p
                            :title="userStore.fullName"
                            class="truncate text-xs leading-3.5 font-bold text-white select-none"
                        >
                            {{ userStore.fullName }}
                        </p>
                        <p
                            v-if="teamStore.hasRequiredInformation"
                            :title="teamStore.team.attributes.name"
                            class="truncate text-xs leading-3.5 text-white select-none group-hover:text-gray-200"
                        >
                            {{ teamStore.team.attributes.name }}
                        </p>
                        <div
                            v-else
                            class="flex items-center text-xs font-medium"
                        >
                            <AlertCircleIcon class="text-warning mr-1 size-5" />
                            <RouterLink
                                :to="{ name: 'complete registration' }"
                                class="text-warning underline"
                            >
                                {{ $t('general.complete_registration') }}
                            </RouterLink>
                        </div>
                    </div>
                    <RouterLink
                        :to="{ name: 'settings' }"
                        class="link-sidebar"
                    >
                        <span class="title">{{ $t('general.settings') }}</span>
                        <SettingsIcon class="h-4.5 w-4.5" />
                    </RouterLink>
                    <button
                        type="button"
                        class="link-sidebar ml-2"
                        @click="logout"
                    >
                        <span class="title">{{ $t('button.logout') }}</span>
                        <Logout2Icon class="h-4.5 w-4.5" />
                    </button>
                </div>
            </div>
        </nav>

        <button
            :class="sidebarIsOpen ? 'opened' : 'closed'"
            class="button-collapse"
            type="button"
            @click="sidebarIsOpen = !sidebarIsOpen"
        >
            <span class="label">
                <span v-if="sidebarIsOpen">{{ $t('general.collapse_menu') }}</span>
                <span v-else>{{ $t('general.expand_menu') }}</span>
            </span>
            <span class="icon">
                <CollapseMenuIcon
                    :class="{
                        'rotate-180': !sidebarIsOpen,
                    }"
                    class="h-4 text-white"
                />
            </span>
        </button>
    </aside>
</template>

<style scoped>
@reference "@css/app.css";

.link-sidebar {
    @apply relative opacity-70 transition duration-300 ease-in-out hover:opacity-100;

    .title {
        @apply invisible absolute -bottom-5 left-1/2 mx-auto -translate-x-1/2 text-[8px] uppercase opacity-0 transition duration-300 ease-in-out;
    }

    &:hover {
        span {
            @apply visible opacity-100;
        }
    }
}
</style>
